import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage4Avaleht from "./pages/Webpage4Avaleht";
import Webpage5Tingimused from "./pages/Webpage5Tingimused";
import Webpage6Terms from "./pages/Webpage6Terms";
import Webpage9K__psised from "./pages/Webpage9K__psised";
import Webpage10Tooted from "./pages/Webpage10Tooted";
import Webpage11Toode from "./pages/Webpage11Toode";
import Webpage12Tellimus from "./pages/Webpage12Tellimus";
import Webpage16Products from "./pages/Webpage16Products";
import Webpage17Product from "./pages/Webpage17Product";
import Webpage18Checkout from "./pages/Webpage18Checkout";
import Webpage19Home from "./pages/Webpage19Home";
import Webpage20Cookie_policy from "./pages/Webpage20Cookie_policy";
import Webpage22Page_not_found from "./pages/Webpage22Page_not_found";

var baseURL = 'https://lilleorukeskus.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiYjQ4MWNlMDc5YmU3MmE5NjQ1ODUwNDUyMzhmYWY5NWU4YWE0ODM1MTBmNjEyODQ3MDdiODRkMGRkOWI1ZWNkNTFkYjM0MWZmMTRhZmRiYzAiLCJpYXQiOjE3MjU5NDg3MzkuMjUzMjM5LCJuYmYiOjE3MjU5NDg3MzkuMjUzMjQzLCJleHAiOjE3MjYwMzUxMzkuMjQ5MjA0LCJzdWIiOiI3NSIsInNjb3BlcyI6W119.PGyuobidriOTqybX8sEpOmVQlcT8mD2sUww-7BC7tqqlxzATo0Dldo3AwKowYl5ESZe3qboKxgfC9a9mx4RcgaT_w3EaG7IQ-ZT6Eg2oI7TqzxgOTl8Hn9rRuugDmKLiWHbEr0V19HVCjN8v1YBBalEJEaZ5T_xO5aywbsHn6X_RZEHbP_TJUVUMegSIib6V_GVwnn0-mtb-wxxedsqTL328vgbzdGdXazINskajrjhVWTeM0sqiLrBLvApllAvNpqq0oJu4a1O4EzyRozvC4hbRX0HAZDll33QkguWvt_4CTz_ynrmY9kJdJ16GMXXi-6UTKVCugR9OkYI68g9si3Aak7PespfDjoeKSRth0dKkPQBMYbensNh3GWRp41ebQnIplk8VwnHsS75MT3vuNj4nnrSB59i4PRMresMMwCwvSZSK-8zJlQKNPuefPshbInYGLK-mf9U2iHjE5Me2eLp0yUJnDC9e-Jvls3t1DK_pGFUCvCoRHux1bF0ELx5qWwRG8_5aX7OliZc2-S5XbnUVuQNEOfE1jyB4PMy404XO5lpAsYIKhFy18OCNw3X7eHp4c_TbXe-i4MTd880zFiuVZXO77oLavTFLm1ZzF90FxaZB9GVw_DvnCgj8tZZoD4G719myKcipyarU3mfybf41i3tKLENRqr5yjd0KvqU',
    'Access-api-key': "eyJpdiI6IjJzU3drd1ozVG1RYkF0T1lpWnBwd1E9PSIsInZhbHVlIjoia0FJVDFrUzBPeWkra0VaMVQ5VEF3Zz09IiwibWFjIjoiMDhmMTY1MjkxYjMyOTJhN2Y3NDFjNTNhNDBhMWE0Nzk5YTgxOGZlNGQzN2MzYWU0MmRkZmY2NmE2MTg5ODcyNSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IkR5bkJoQVRtNjFSZlV6blBoRWNLUXc9PSIsInZhbHVlIjoibDRkeURFNHVvMXcrbnVxbFRpNTQzUT09IiwibWFjIjoiNGE3ZWE3NjA2NGIzZDM2OThjMWE5MzZiMTY5NGJjMTUzNDExZThkM2Q4Nzc0MDVhYWEzNTlhNGNkMmE0YTI5MyIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-WW9QW6"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage4Avaleht />} exact />;
                                                                    <Route path="/et/tingimused" element={<Webpage5Tingimused />} exact />;
                                                                    <Route path="/en/terms" element={<Webpage6Terms />} exact />;
                                                                    <Route path="/et/kupsised" element={<Webpage9K__psised />} exact />;
                                                                    <Route path="/et/tooted" element={<Webpage10Tooted />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage11Toode />} exact />;
                                                                    <Route path="/et/tellimus" element={<Webpage12Tellimus />} exact />;
                                                                    <Route path="/en/products" element={<Webpage16Products />} exact />;
                                                                    <Route path="/en/product/:id" element={<Webpage17Product />} exact />;
                                                                    <Route path="/en/checkout" element={<Webpage18Checkout />} exact />;
                                                                    <Route path="/en/" element={<Webpage19Home />} exact />;
                                                                    <Route path="/en/cookiepolicy" element={<Webpage20Cookie_policy />} exact />;
                                                                    <Route path="/et/*" element={<Webpage22Page_not_found />} exact />;
                                                <Route path="*" element={<Webpage22Page_not_found />} exact />;
                                                                
                                            <Route path="/toode/alladini-komplekt-4-klaasiga" element={<Redirect to="/toode/alladini-karahvin-kuldne-13l-4-joogiklaasi" />} exact />;
                                            <Route path="/tootekategooria/kingitused-lilleorust/" element={<Redirect to="/tooted?category=15" />} exact />;
                                            <Route path="/tootekategooria/kingitused-lilleorust/valitud-kingitused/" element={<Redirect to="/tooted?category=15" />} exact />;
                                            <Route path="/tootekategooria/jooga/" element={<Redirect to="/tooted?category=6" />} exact />;
                                            <Route path="/jooga/jooga-3/" element={<Redirect to="/tooted?category=24" />} exact />;
                                            <Route path="/tootekategooria/jooga/syvajooga/" element={<Redirect to="/tooted?category=25" />} exact />;
                                            <Route path="/tootekategooria/jooga/syvajooga/jooga-suvajooga/" element={<Redirect to="/tooted?category=52" />} exact />;
                                            <Route path="/tootekategooria/jooga/syvajooga/jooga-suvajooga/pildid/" element={<Redirect to="/tooted?category=25" />} exact />;
                                            <Route path="/tootekategooria/jooga/syvajooga/sisemine-jooga/" element={<Redirect to="/tooted?category=25" />} exact />;
                                            <Route path="/tootekategooria/raamatud/" element={<Redirect to="/tooted?category=1" />} exact />;
                                            <Route path="/tootekategooria/raamatud/ingliskeelsed-raamatud/" element={<Redirect to="/tooted?category=23" />} exact />;
                                            <Route path="/tootekategooria/teed/" element={<Redirect to="/tooted?category=2" />} exact />;
                                            <Route path="/tootekategooria/teed/lilleoru-teesegud/" element={<Redirect to="/tooted?category=60" />} exact />;
                                            <Route path="/tootekategooria/teed/teesegud/" element={<Redirect to="/tooted?category=38" />} exact />;
                                            <Route path="/tootekategooria/mesindus/" element={<Redirect to="/tooted?category=16" />} exact />;
                                            <Route path="/tootekategooria/mesindus/mesindus-2/" element={<Redirect to="/tooted?category=18" />} exact />;
                                            <Route path="/tootekategooria/mesindus/punkti-meed/" element={<Redirect to="/tooted?category=19" />} exact />;
                                            <Route path="/tootekategooria/mesindus/puidust-kinkekastid/" element={<Redirect to="/tooted?category=20" />} exact />;
                                            <Route path="/tootekategooria/mesindus/kinkekastid/" element={<Redirect to="/tooted?category=105" />} exact />;
                                            <Route path="/tootekategooria/hudrolaadid/" element={<Redirect to="/tooted?category=69" />} exact />;
                                            <Route path="/tootekategooria/aroomid-ja-viirukid/" element={<Redirect to="/tooted?category=9" />} exact />;
                                            <Route path="/tootekategooria/tervisetooted/" element={<Redirect to="/tooted?category=7" />} exact />;
                                            <Route path="/tootekategooria/looduskosmeetika/" element={<Redirect to="/tooted?category=5" />} exact />;
                                            <Route path="/tootekategooria/veenoud/" element={<Redirect to="/tooted?category=4" />} exact />;
                                            <Route path="/tootekategooria/veepudelid/" element={<Redirect to="/tooted?category=28" />} exact />;
                                            <Route path="/tootekategooria/keraamilised-tassid/" element={<Redirect to="/tooted?category=3" />} exact />;
                                            <Route path="/tootekategooria/ehted/" element={<Redirect to="/et/tooted?category=8" />} exact />;
                                            <Route path="/tootekategooria/ehted/puidust-ehted/" element={<Redirect to="/tooted?category=128" />} exact />;
                                            <Route path="/tootekategooria/ehted/malad/" element={<Redirect to="/tooted?category=48" />} exact />;
                                            <Route path="/tootekategooria/ehted/ehted-2/" element={<Redirect to="/tooted?category=47" />} exact />;
                                            <Route path="/tootekategooria/markmikud/" element={<Redirect to="/tooted?category=10" />} exact />;
                                            <Route path="/tootekategooria/markmikud/kristiina-pollu/" element={<Redirect to="/tooted?category=26" />} exact />;
                                            <Route path="/tootekategooria/riided/" element={<Redirect to="/tooted?category=14" />} exact />;
                                            <Route path="/tootekategooria/riided/ishwara-pildiga-tooted/" element={<Redirect to="/tooted?category=93" />} exact />;
                                            <Route path="/tootekategooria/riided/dakini/" element={<Redirect to="/tooted?category=14" />} exact />;
                                            <Route path="/tootekategooria/kunst/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/avatar/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/ahti-sepsivart/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/kadri-kangilaski/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/kaie-kal/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/kaija-kesa/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/toomas-tonissoo/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/kauba-tagastamine/" element={<Redirect to="/tingimused" />} exact />;
                                            <Route path="/kontakt/" element={<Redirect to="/" />} exact />;
                                            <Route path="/minu-konto/" element={<Redirect to="/" />} exact />;
                                            <Route path="/ostutingimused/" element={<Redirect to="/tingimused" />} exact />;
                                            <Route path="/pood" element={<Redirect to="/tooted" />} exact />;
                                            <Route path="/tarnetingimused/" element={<Redirect to="/tingimused" />} exact />;
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}